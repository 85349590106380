export class UserWorkTask {
  static readonly DIAGNOSTIC_ID = 1;
  static readonly MAINTENANCE_ID = 2;
  static readonly REPAIR_ID = 3;
  static readonly REPLACEMENT_ID = 4;
  static readonly ADJUST_ID = 5;

  static readonly DIAGNOSTIC_LABEL = "Diagnóstico";
  static readonly MAINTENANCE_LABEL = "Mantenimiento";
  static readonly REPAIR_LABEL = "Reparación";
  static readonly REPLACEMENT_LABEL = "Reemplazo";
  static readonly ADJUST_LABEL = "Ajuste";

  readonly id!: number;
  readonly name!: string;

  constructor(data: Partial<UserWorkTask>) {
    Object.assign(this, data);
  }
}
