import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import {
  Box,
  Drawer as MuiDrawer,
  AppBar as MuiAppBar,
  Toolbar,
  CssBaseline,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MobileNavBar from "./MobileNavBar";
import LAXLOGO from "../img/LAXLOGO.png";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";

import NotificationBell from "./NotificationBell";
import LayoutMenu from "./LayoutMenu";
import { useState } from "react";
import { useAppSelector } from "../redux-store/hooks";
import { PermissionEnum } from "../enum/permission.enum";
import ProfileDropdown from "./ProfileDropdown";

const drawerWidth = 240;
const logoWidthOpen = "100%";
const logoWidthClosed = "100%";

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface DrawerHeaderProps {
  open: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")<DrawerHeaderProps>(({ theme, open }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  width: open ? logoWidthOpen : logoWidthClosed,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MainLayout() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(true);

  const user = useAppSelector((state) => state.userReducer.user);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const appBarClosed = {
    marginLeft: 0,
    width: `calc(100% - ${theme.spacing(7)})`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${theme.spacing(8)})`,
    },
  };

  return (
    <BrowserRouter>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {isMobile ? (
          <MobileNavBar ListContent={<LayoutMenu open={open} />} />
        ) : (
          <>
            <AppBar position="fixed" open={open} sx={open ? {} : appBarClosed}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label={open ? "close drawer" : "open drawer"}
                  onClick={open ? handleDrawerClose : handleDrawerOpen}
                  edge="start"
                >
                  {open ? <ChevronLeftIcon /> : <MenuIcon />}
                </IconButton>
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: { xs: "none", md: "flex" } }} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {user.permissions.includes(
                    PermissionEnum.WORKSHOP_PURCHASE_INDEX
                  ) && <NotificationBell />}
                  <ProfileDropdown user={user} />
                </Box>
              </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
              <DrawerHeader open={open}>
                <img
                  src={LAXLOGO}
                  alt="Logo"
                  style={{
                    width: open ? `80px` : `70px`,
                    height: "auto",
                    padding: open ? `5px` : `10px`,
                  }}
                />
              </DrawerHeader>
              <LayoutMenu open={open} />
            </Drawer>
          </>
        )}
        <Box
          mt={10}
          style={{
            width:
              open && !isMobile
                ? `calc(100% - ${drawerWidth}px)`
                : `calc(100%)`,
            height: "auto",
            minHeight: "200px",
            padding: "0 1% 0 1%",
          }}
        >
          <AppRoutes />
        </Box>
      </Box>
    </BrowserRouter>
  );
}
