import { useLocation } from "react-router-dom";
import { RoutePath } from "../AppRoutes";

import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import PurchaseBrandRow from "./PurchaseBrandRow";
import PriceInput from "../WorkshopPurchaseItem/PriceInput";
import { FormattedNumber } from "react-intl";
import { WorkshopPurchaseItem } from "../../entities/workshop-purchase-item.entity";
import { WorkshopPurchaseItemState } from "../../entities/workshop-purchase-item-state.entity";
import { useDarkMode } from "../ThemeContext";
import { FieldError } from "../../types/FieldError";

interface PurchaseItemRow {
  isEditionDisabled?: boolean;
  item: WorkshopPurchaseItem;
  setLatestItemPrices: React.Dispatch<React.SetStateAction<any[]>>;
  latestItemPrices: any[];
  onStateChange: (event: SelectChangeEvent<number>, itemId: number) => void;
  getNextStates: Function;
  saveNewPrice: Function;
  onDescriptionBlur: Function;
  errors?: FieldError[];
  itemService: { getTotal: (item: WorkshopPurchaseItem) => number };
}

export default function PurchaseItemRow({
  isEditionDisabled,
  item,
  latestItemPrices,
  onStateChange,
  getNextStates,
  saveNewPrice,
  onDescriptionBlur,
  errors,
  itemService,
}: PurchaseItemRow) {
  const location = useLocation();
  const isBudgetView = location.pathname === RoutePath.BUDGET;
  const { isDarkMode } = useDarkMode();

  const getColors = (
    stateId: number,
    isBudgetView: boolean
  ): { backgroundColor: string; color: string } => {
    if (isBudgetView) {
      return { backgroundColor: "none", color: "none" };
    }

    switch (stateId) {
      case WorkshopPurchaseItemState.QUOTE_ID:
      case WorkshopPurchaseItemState.BUY_ID:
      case WorkshopPurchaseItemState.MISSING_FROM_FACTORY_ID:
      case WorkshopPurchaseItemState.REPLACE_PART_ID:
      case WorkshopPurchaseItemState.IN_STOCK_ID:
      case WorkshopPurchaseItemState.REPLACE_PART_ID:
        return { backgroundColor: "#fef3c7", color: "#d97706" };
      case WorkshopPurchaseItemState.QUOTED_ID:
      case WorkshopPurchaseItemState.LOGISTIC_TRANSIT_ID:
      case WorkshopPurchaseItemState.IN_VERIFICATION_ID:
      case WorkshopPurchaseItemState.DELIVERED_REPAIRED_SAMPLE_ID:
      case WorkshopPurchaseItemState.IN_OUTSOURCED_WORKSHOP_ID:
        return { backgroundColor: "#dbeafe", color: "#2563eb" };
      case WorkshopPurchaseItemState.VERIFICATION_PENDING_ID:
      case WorkshopPurchaseItemState.FINISHED_ID:
        return { backgroundColor: "#d1fae5", color: "#059669" };
      case WorkshopPurchaseItemState.REJECTED_ID:
        return { backgroundColor: "#d1d5db", color: "#6b7280" };
      default:
        return { backgroundColor: "none", color: "rgba(0, 0, 0, 0.87)" };
    }
  };

  const validPrices = latestItemPrices.filter(
    (item) => item !== undefined && item !== null
  );

  return (
    <>
      <TableRow
        key={item.id}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          whiteSpace: "nowrap",
        }}
      >
        {validPrices.length > 0 && (
          <TableCell sx={{ fontSize: ".8rem" }}>
            {new Date(validPrices[0].date).toLocaleDateString()}
          </TableCell>
        )}
        <Tooltip
          title={item.budgetItem.repairSubitem?.name}
          arrow
          placement="top"
        >
          <TableCell
            sx={{
              fontSize: ".8rem",
              maxWidth: "150px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            component="th"
            scope="row"
            size="small"
          >
            {item.has_sample ? (
              <Tooltip title="Muestra disponible">
                <Box component="span">🔷 </Box>
              </Tooltip>
            ) : (
              ""
            )}
            {item.budgetItem.repairSubitem?.name}
          </TableCell>
        </Tooltip>
        <TableCell
          sx={{
            whiteSpace: "normal",
          }}
        >
          <Box>
            <PurchaseBrandRow
              item={item}
              isEditionDisabled={isEditionDisabled}
              isBudgetView={isBudgetView}
            />
          </Box>
        </TableCell>
        <TableCell>
          <Tooltip title={item.description} placement="top" arrow>
            <TextField
              size="small"
              disabled={isEditionDisabled || isBudgetView}
              defaultValue={item.description}
              onBlur={(e) => onDescriptionBlur(item, e.target.value)}
              error={Boolean(
                errors && errors.find((e) => e.field === "description")
              )}
              helperText={
                errors?.find((e) => e.field === "description")?.message
              }
              InputProps={{
                sx: {
                  fontSize: ".8rem",
                  fontWeight: "450",
                },
              }}
            />
          </Tooltip>
        </TableCell>
        <TableCell>
          <PriceInput
            id={item.id}
            price={item.price}
            lastPrice={latestItemPrices[0]?.workshopPurchaseItem?.price ?? null}
            disabled={
              item.workshopPurchaseItemState.id !==
                WorkshopPurchaseItemState.QUOTE_ID ||
              isEditionDisabled ||
              isBudgetView
            }
            onBlur={() => saveNewPrice(item)}
          />
        </TableCell>
        <TableCell sx={{ fontSize: ".8rem" }}>
          <FormattedNumber value={item.quantity} />
        </TableCell>
        <TableCell sx={{ fontSize: ".8rem" }}>
          <FormattedNumber
            value={itemService.getTotal(item) || 0}
            style="currency"
            currency="ARS"
          />
        </TableCell>
        <TableCell>
          <FormControl
            size="small"
            sx={{
              ...getColors(item.workshopPurchaseItemState.id, isBudgetView),
              borderRadius: "5px",
              width: "100%",
            }}
          >
            {item.price ? (
              <Select
                fullWidth
                disabled={isEditionDisabled || isBudgetView}
                labelId={`state-select-label-${item.id}`}
                id={`state-select-${item.id}`}
                value={item.workshopPurchaseItemState.id}
                onChange={(e) =>
                  onStateChange(e as SelectChangeEvent<number>, item.id)
                }
                error={Boolean(
                  errors &&
                    errors.find(
                      (e) => e.field === "workshop_purchase_item_state_id"
                    )
                )}
                sx={{
                  ".MuiSelect-icon": {
                    color: isDarkMode ? "#1C252E" : "rgba(0, 0, 0, 0.54)",
                  },
                }}
              >
                {getNextStates(item.workshopPurchaseItemState.id).map(
                  (state: any) => (
                    <MenuItem key={`state-option-${state.id}`} value={state.id}>
                      <Typography
                        sx={{
                          ...getColors(state.id, isBudgetView),
                          fontWeight: "450",
                          fontSize: ".8rem",
                          borderRadius: "5px",
                          textAlign: "center",
                          padding: "2px 10px 2px 10px",
                        }}
                      >
                        {state.name}
                      </Typography>
                    </MenuItem>
                  )
                )}
              </Select>
            ) : (
              <Tooltip
                title={
                  item.price
                    ? ""
                    : "Para avanzar de estado se requiere una cotización en el campo PRECIO"
                }
                arrow
              >
                <Select
                  fullWidth
                  disabled={
                    isEditionDisabled || isBudgetView || Boolean(!item.price)
                  }
                  labelId={`state-select-label-${item.id}`}
                  id={`state-select-${item.id}`}
                  value={item.workshopPurchaseItemState.id}
                  label="Estado"
                  onChange={(e) =>
                    onStateChange(e as SelectChangeEvent<number>, item.id)
                  }
                  error={Boolean(
                    errors &&
                      errors.find(
                        (e) => e.field === "workshop_purchase_item_state_id"
                      )
                  )}
                  sx={{
                    ".MuiSelect-icon": {
                      color: isDarkMode ? "#1C252E" : "rgba(0, 0, 0, 0.54)",
                    },
                  }}
                >
                  {getNextStates(item.workshopPurchaseItemState.id).map(
                    (state: any) => (
                      <MenuItem
                        key={`state-option-${state.id}`}
                        value={state.id}
                      >
                        <Typography
                          sx={{
                            ...getColors(state.id, isBudgetView),
                            fontWeight: "450",
                            fontSize: "0.700rem",
                            borderRadius: "5px",
                            textAlign: "center",
                            padding: "2px 10px 2px 10px",
                            "-webkit-text-fill-color": () =>
                              isEditionDisabled || isBudgetView || !item.price
                                ? "#1C252E"
                                : "inherit",
                          }}
                        >
                          {state.name}
                        </Typography>
                      </MenuItem>
                    )
                  )}
                </Select>
              </Tooltip>
            )}

            {errors &&
              errors.find(
                (e) => e.field === "workshop_purchase_item_state_id"
              ) && (
                <FormHelperText error={true}>
                  {
                    errors.find(
                      (e) => e.field === "workshop_purchase_item_state_id"
                    )?.message
                  }
                </FormHelperText>
              )}
          </FormControl>
        </TableCell>
      </TableRow>
    </>
  );
}
