export class User {
  readonly id!: number;
  readonly username!: string;
  readonly email!: string;
  readonly name!: string;
  readonly lastname!: string;
  readonly fullname!: string;
  readonly identification_number!: string;
  readonly permissions!: string;
  readonly roles!: string;

  constructor(data: Partial<User>) {
    Object.assign(this, data);
  }
}
