import ModeNightIcon from "@mui/icons-material/ModeNight";
import Brightness7Icon from "@mui/icons-material/Brightness7";

import { ToggleButton, Tooltip, Typography } from "@mui/material";
import { useDarkMode } from "./ThemeContext";

export default function ThemeSwitcher() {
  const { isDarkMode, toggleTheme } = useDarkMode();

  return (
    <Tooltip
      arrow
      title={isDarkMode ? "Habilitar modo claro" : "Habilitar modo oscuro"}
    >
      <ToggleButton
        value="check"
        selected={isDarkMode}
        onChange={toggleTheme}
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "left",
          textTransform: "none",
        }}
      >
        {isDarkMode ? (
          <>
            <Brightness7Icon fontSize="small" />
            <Typography sx={{ marginLeft: "10px" }}>Tema Claro</Typography>
          </>
        ) : (
          <>
            <ModeNightIcon fontSize="small" />
            <Typography sx={{ marginLeft: "10px" }}>Tema Oscuro</Typography>
          </>
        )}
      </ToggleButton>
    </Tooltip>
  );
}
