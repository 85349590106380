import { useState, useCallback, useEffect, useRef } from "react";
import { enqueueSnackbar } from "notistack";
import { UserWorkService } from "../services/UserWorkService";

const TIMER_START_KEY = (budgetId: number) => `timer-start-${budgetId}`;

export const useTimer = (budgetId: number, onStatusChange: (budgetId: number, isActive: boolean) => void) => {
    const [isActive, setIsActive] = useState(false);
    const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const userWorkService = new UserWorkService();

    const updateElapsedTime = useCallback(() => {
        const startTimestamp = localStorage.getItem(TIMER_START_KEY(budgetId));
        if (!startTimestamp) return;

        const startTime = new Date(parseInt(startTimestamp, 10));
        const now = new Date();
        const elapsed = now.getTime() - startTime.getTime();

        const hours = Math.floor(elapsed / (1000 * 60 * 60));
        const minutes = Math.floor((elapsed % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((elapsed % (1000 * 60)) / 1000);

        setTime({ hours, minutes, seconds });
    }, [budgetId]);

    const handleStartClick = async () => {
        if (isActive) {
            enqueueSnackbar("No es posible iniciar otra tarea sin pausar la actual.", { variant: "error" });
            return;
        }

        try {
            const response = await userWorkService.start(budgetId);

            if (response.error) {
                enqueueSnackbar(response.error, { variant: "error" });
                return;
            }
            if (response.status === 404) {
                enqueueSnackbar("El presupuesto no existe", { variant: "error" });
                return;
            }
            const startDate = new Date().getTime().toString();
            localStorage.setItem(TIMER_START_KEY(budgetId), startDate);
            setIsActive(true);
            onStatusChange(budgetId, true);

            intervalRef.current = setInterval(updateElapsedTime, 1000);
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: "error" });
        }
    };

    const handleStopClick = async (selectedTaskId: number | null, comment: string) => {
        if (!selectedTaskId) {
            enqueueSnackbar("Debe seleccionar un tipo de trabajo.", { variant: "warning" });
            return;
        }

        try {
            const response = await userWorkService.stop(budgetId, selectedTaskId, comment);

            if (intervalRef.current) clearInterval(intervalRef.current);
            intervalRef.current = null;
            setIsActive(false);
            onStatusChange(budgetId, false);
            localStorage.removeItem(TIMER_START_KEY(budgetId));
            const [hours, minutes, seconds] = response.total_time.split(":").map(Number);
            setTime({ hours, minutes, seconds });
            enqueueSnackbar("Tarea detenida correctamente", { variant: "success" });
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: "error" });
        }
    };

    useEffect(() => {
        const startTimestamp = localStorage.getItem(TIMER_START_KEY(budgetId));
        if (startTimestamp) {
            setIsActive(true);
            onStatusChange(budgetId, true);
            updateElapsedTime();
            intervalRef.current = setInterval(updateElapsedTime, 1000);
        }

        return () => {
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
    }, [budgetId, updateElapsedTime, onStatusChange]);

    const resetTimer = () => {
        setIsActive(false);
        setTime({ hours: 0, minutes: 0, seconds: 0 });
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
        localStorage.removeItem(TIMER_START_KEY(budgetId));
        onStatusChange(budgetId, false);
    };

    return { isActive, time, handleStartClick, handleStopClick, resetTimer };
};
