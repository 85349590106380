import { TypeKanbanBudget } from "./TypeKanbanBudget";
import { Box, Typography, Avatar, Tooltip } from "@mui/material";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StateProgressBar from "../../../Purchase/StateProgressBar";
import { WorkshopBudgetState } from "../../../../entities/workshop-budget-state";
import {
  ArrivalDateIcon,
  ExitDateIcon,
  UndatedIcon,
  TurnIcon,
} from "./DateIcon";
import { useDarkMode } from "../../../ThemeContext";
import { useContext } from "react";
import { PurchaseContext } from "../../Purchase/context/PurchaseContext";

interface TaskProps {
  task: TypeKanbanBudget;
  provided: any;
  handleOpen: any;
}

interface BudgetStatusProps {
  status: any;
  appointment: Date | null;
  workshop_arrival_date: Date | null;
  workshop_exit_date: Date | null;
}

const arrivalDate = [
  WorkshopBudgetState.ADMITTED_ID,
  WorkshopBudgetState.DIAGNOSIS_ID,
  WorkshopBudgetState.PURCHASE_REQUESTED_ID,
  WorkshopBudgetState.REPAIR_ID,
  WorkshopBudgetState.FINISHED_ID,
];
const appointmentDate = [WorkshopBudgetState.APPOINTMENT_ID];
const exitDate = [WorkshopBudgetState.DELIVERED_ID];

const BudgetStatus = ({
  status,
  appointment,
  workshop_arrival_date,
  workshop_exit_date,
}: BudgetStatusProps) => {
  switch (true) {
    case appointmentDate.includes(status):
      return <TurnIcon appointment={appointment} />;
    case arrivalDate.includes(status):
      return (
        <ArrivalDateIcon
          workshop_arrival_date={workshop_arrival_date}
          workshop_exit_date={workshop_exit_date}
        />
      );
    case exitDate.includes(status):
      return <ExitDateIcon workshop_exit_date={workshop_exit_date} />;
    default:
      return <UndatedIcon undated="Sin turno" />;
  }
};

const BudgetItemsKanban = ({ task, provided, handleOpen }: TaskProps) => {
  const { isDarkMode } = useDarkMode();
  const {
    id,
    carId,
    brandCar,
    modelCar,
    company,
    nameCreate,
    lastnameCreate,
    tags,
    in_workshop,
    purchase,
    appointment,
    workshop_budget_state_id,
    workshop_arrival_date,
    workshop_exit_date,
  } = task;

  const { purchases } = useContext(PurchaseContext);

  return (
    <Box
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      sx={{
        width: "100%",
        cursor: "pointer!important",
        backgroundColor: isDarkMode ? "#2E363E" : "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 1,
        alignItems: "start",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "12px",
        paddingX: 1.5,
        paddingY: 1.5,
        minWidth: "350px",
        ":hover": {
          background: isDarkMode
            ? "#141A21"
            : "var(--ds-background-neutral-subtle-hovered,#F4F5F7)",
        },
      }}
      onClick={handleOpen}
    >
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          {tags.map((tag, id) => (
            <>
              <Typography
                key={id}
                sx={{
                  paddingX: "10px",
                  paddingY: "2px",
                  fontSize: "12px",
                  fontWeight: "medium",
                  borderRadius: "4px",
                  whiteSpace: "nowrap",
                }}
                style={{ backgroundColor: tag.bg, color: tag.text }}
              >
                {tag.title}
              </Typography>
              {purchase && (
                <Tooltip arrow title={"Estado de solicitud de compra"}>
                  <Typography
                    key={id}
                    sx={{
                      paddingX: "10px",
                      paddingY: "2px",
                      fontSize: "12px",
                      fontWeight: "medium",
                      borderRadius: "4px",
                      whiteSpace: "nowrap",
                    }}
                    style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                  >
                    {tag.PurchaseState}
                  </Typography>
                </Tooltip>
              )}
            </>
          ))}
        </Box>
        {in_workshop ? (
          <Tooltip arrow title="Vehículo en taller">
            <CarRepairIcon color="success" />
          </Tooltip>
        ) : (
          <Tooltip arrow title="Vehículo fuera de taller">
            <CarRepairIcon color="disabled" />
          </Tooltip>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "start",
          flexDirection: "column",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "15.5px",
                fontWeight: "medium",
                marginRight: "10px",
              }}
            >
              {carId}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "15.5px",
                fontWeight: "medium",
                marginRight: "10px",
              }}
            >
              {brandCar}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "15.5px",
                fontWeight: "medium",
              }}
            >
              {modelCar}
            </Typography>
          </Box>
        </Box>

        <Typography
          variant="body2"
          sx={{
            fontSize: "11px",
            color: "gray.500",
          }}
        >
          {company}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {purchase ? (
          <Box
            sx={{
              borderRadius: "4px",
              background: "#2e7d32",
              padding: "2px 5px 2px 5px",
              display: "flex",
              minHeight: "24px",
              alignItems: "center",
            }}
          >
            <Tooltip arrow title="Solicitud de compra">
              <ShoppingCartIcon sx={{ color: "white", fontSize: "1rem" }} />
            </Tooltip>
          </Box>
        ) : (
          <Box
            sx={{
              borderRadius: "4px",
              background: "rgba(0, 0, 0, 0.26)",
              padding: "2px 5px 2px 5px",
              display: "flex",
              minHeight: "24px",
              alignItems: "center",
            }}
          >
            <Tooltip arrow title="Sin solicitud de compra">
              <ShoppingCartIcon sx={{ color: "white", fontSize: "1rem" }} />
            </Tooltip>
          </Box>
        )}
        <Box sx={{ marginLeft: "10px", width: "100%" }}>
          {purchase &&
            purchases
              .filter((purchase) => purchase.budget.id === id)
              .sort((a, b) => a.id - b.id)
              .map((p) => (
                <StateProgressBar
                  states={p.workshopPurchaseItems.map(
                    (item: any) => item.workshopPurchaseItemState.name
                  )}
                />
              ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <Box>
          <BudgetStatus
            status={workshop_budget_state_id}
            appointment={appointment}
            workshop_arrival_date={workshop_arrival_date}
            workshop_exit_date={workshop_exit_date}
          />
        </Box>
        <Box>
          {nameCreate ? (
            <Avatar sx={{ width: 24, height: 24 }}>
              <Typography sx={{ fontSize: "0.7rem" }}>
                {nameCreate.substring(0, 1)}
                {lastnameCreate.substring(0, 1)}
              </Typography>
            </Avatar>
          ) : (
            <Avatar sx={{ width: 24, height: 24 }}></Avatar>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BudgetItemsKanban;
