import {
  Breadcrumbs,
  Divider,
  IconButton,
  Box,
  Typography,
  Alert,
  Stack,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tabs,
  Tab,
  FormControl,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import TopPanelBudget from "./TopPanelBudget";
import { useContext, useEffect, useState } from "react";
import { PurchaseContext } from "../Purchase/context/PurchaseContext";
import WorkshopPurchaseItemList from "../../Purchase/WorkshopPurchaseItemList";
import { WorkshopPurchaseState } from "../../../entities/workshop-purchase-state.entity";
import { WorkshopPurchase } from "../../../entities/workshop-purchase.entity";
import StateProgressBar from "../../Purchase/StateProgressBar";
import History from "../../Purchase/History";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { BudgetService } from "../../../services/BudgetService";
import { enqueueSnackbar } from "notistack";
import { WorkshopBudgetState } from "../../../entities/workshop-budget-state";
import { FieldError } from "../../../types/FieldError";
import { Budget } from "../../../entities/budget.entity";
import { useDarkMode } from "../../ThemeContext";
import UserAssignment from "./UserAssignment";
import { parseUTCDate } from "../../../utils/dateUtils";
import { PermissionEnum } from "../../../enum/permission.enum";
import { useAppSelector } from "../../../redux-store/hooks";

interface ComponentProps {
  budget: any;
  handleClose: () => void;
  onBudgetChange: (budget: Budget) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ModalDetailBudget({
  budget,
  handleClose,
  onBudgetChange,
}: ComponentProps) {
  const budgetService = new BudgetService();
  const { isDarkMode } = useDarkMode();
  const [value, setValue] = useState(0);
  const [errors, setErrors] = useState<FieldError[]>();
  const user = useAppSelector((state) => state.userReducer.user);

  const [selectedState, setSelectedState] = useState(
    budget.workshop_budget_state_id
  );

  const { purchases, setPurchases, setSelectedPurchase } =
    useContext(PurchaseContext);

  const purchaseInBudget = purchases.find(
    (purchase) => purchase.budget.id === budget.id
  );
  
  const isEditionDisabled = [
    WorkshopPurchaseState.CANCELLED_ID,
    WorkshopPurchaseState.EXPIRED_ID,
    WorkshopPurchaseState.REJECTED_ID,
  ].includes(purchaseInBudget?.workshopPurchaseState?.id ?? -1);
  
  const onPurchaseChange = (purchase: WorkshopPurchase) => {
    setPurchases(
      purchases.map((p: any) => {
        if (p.id === purchase.id) {
          setSelectedPurchase(purchase);
          return purchase;
        }
        return p;
      })
    );
  };
  
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  
  const handleUpdateBudgetState = async (event: SelectChangeEvent) => {
    setErrors(undefined);
    const newStateId = Number(event.target.value);
    
    try {
      const updatedBudget = await budgetService.update(budget.id, {
        workshop_budget_state_id: newStateId,
      });
      
      onBudgetChange(updatedBudget);
      enqueueSnackbar("Se actualizó el estado del presupuesto", {
        variant: "success",
      });
      setSelectedState(newStateId);
    } catch (error: any) {
      console.error("Error updating budget state:", error);
      setErrors([
        { field: "workshop_budget_state_id", message: error.message },
      ]);
      enqueueSnackbar("Error al actualizar el estado del presupuesto", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    setSelectedState(budget.workshop_budget_state_id);
  }, [budget]);

  const gridContainerStyles = {
    display: "grid",
    gridTemplateAreas: `
    "titel1 titel3"
    "box1 box3"
    "titel2 titel4"  
    "box2 box4"
    `,
    gridTemplateColumns: "1fr 0.5fr",

    gridTemplateRows: "auto auto auto auto",
    gap: "10px",
    padding: "10px",

    margin: "10px 0px 0px 0px",
    width: "100%",
  };

  const modalContentStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "85%",
    bgcolor: "#fff",
    border: "2px solid #000",
    boxShadow: 24,
    p: 1,
    overflow: "hidden",
    display: "grid",
    gridTemplateRows: "auto auto 1fr",
  };

  const styles = {
    select: {
      backgroundColor: "#2e7d32",
      color: "white",
      textAlign: "center" as const,
    },
    rowCart: {
      width: "100%",
      padding: "6px",
    },
    titleRow: {
      minWidth: "80px",
      display: "flex",
      whiteSpace: "nowrap",
    },
  };

  const styleBgTitelDarkMode = () => {
    switch (true) {
      case isDarkMode:
        return "rgb(64 122 214 / 8%)";
      default:
        return "rgba(214, 20, 50, 0.08)";
    }
  };
  const styleBorderDarkMode = () => {
    switch (true) {
      case isDarkMode:
        return "1px solid #fff";
      default:
        return "#fcdadc";
    }
  };

  return (
    <Box
      sx={{
        ...modalContentStyle,
        backgroundColor: isDarkMode ? "#1C252E" : "#fff",
        overflowY: "scroll",
      }}
    >
      <Box>
        <Box display="grid" gridTemplateColumns="1fr auto">
          <Breadcrumbs aria-label="breadcrumb" sx={{ display: "grid" }}>
            <strong> {budget.company.name} </strong>
            <Box>
              <strong>ID de presupuesto: {budget.id}</strong>
            </Box>
            <Box>
              <strong>
                Estado en PGF: {budget.budgetState.client_facing_name}
              </strong>
            </Box>
          </Breadcrumbs>
          <IconButton onClick={() => handleClose()} sx={{ cursor: "pointer" }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />

        <Box
          sx={{
            padding: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <h2
              style={{ margin: "0px" }}
            >{`${budget.car.identification} ${budget.car.brand.name} ${budget.car.carModel?.name}`}</h2>
            <Box sx={{ minWidth: 120 }}>
              <FormControl sx={{ minWidth: 300 }}>
                <Select
                  value={selectedState}
                  onChange={handleUpdateBudgetState}
                  style={styles.select}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  size="small"
                  disabled={!user.permissions.includes(PermissionEnum.BUDGET_WORKSHOP_UPDATE)}
                >
                  <MenuItem value={WorkshopBudgetState.PENDING_ID}>
                    {WorkshopBudgetState.PENDING_LABEL}
                  </MenuItem>
                  <MenuItem value={WorkshopBudgetState.APPOINTMENT_ID}>
                    {WorkshopBudgetState.APPOINTMENT_LABEL}
                  </MenuItem>
                  <MenuItem value={WorkshopBudgetState.ADMITTED_ID}>
                    {WorkshopBudgetState.ADMITTED_LABEL}
                  </MenuItem>
                  <MenuItem value={WorkshopBudgetState.DIAGNOSIS_ID}>
                    {WorkshopBudgetState.DIAGNOSIS_LABEL}
                  </MenuItem>
                  <MenuItem value={WorkshopBudgetState.PURCHASE_REQUESTED_ID}>
                    {WorkshopBudgetState.PURCHASE_REQUESTED_LABEL}
                  </MenuItem>
                  <MenuItem value={WorkshopBudgetState.REPAIR_ID}>
                    {WorkshopBudgetState.REPAIR_LABEL}
                  </MenuItem>
                  <MenuItem value={WorkshopBudgetState.FINISHED_ID}>
                    {WorkshopBudgetState.FINISHED_LABEL}
                  </MenuItem>
                  <MenuItem value={WorkshopBudgetState.DELIVERED_ID}>
                    {WorkshopBudgetState.DELIVERED_LABEL}
                  </MenuItem>
                </Select>
                {errors &&
                  errors.find(
                    (e: any) => e.field === "workshop_budget_state_id"
                  ) && (
                    <FormHelperText error={true}>
                      {
                        errors.find(
                          (e: any) => e.field === "workshop_budget_state_id"
                        )?.message
                      }
                    </FormHelperText>
                  )}
              </FormControl>
            </Box>
          </Box>

          <TopPanelBudget budget={budget} />

          <Box>
            <Paper>
              <Box sx={gridContainerStyles}>
                <Box
                  sx={{
                    background: styleBgTitelDarkMode(),
                    border: styleBorderDarkMode(),
                    padding: "3px",
                    borderRadius: "5px",
                    textAlign: "center",
                    gridArea: "titel1",
                  }}
                >
                  <strong>Descripción</strong>
                </Box>
                <Box
                  sx={{
                    gridArea: "box1",
                    overflow: "auto",
                    height: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      border: styleBorderDarkMode(),
                      minHeight: "100%",
                      borderRadius: "5px",
                      padding: "5px",
                      maxHeight: "133px",
                      overflow: "auto",
                      wordBreak: "break-word",
                    }}
                  >
                    {budget.description}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    background: styleBgTitelDarkMode(),
                    border: styleBorderDarkMode(),
                    padding: "3px",
                    borderRadius: "5px",
                    textAlign: "center",
                    gridArea: "titel2",
                  }}
                >
                  <strong>Observaciones</strong>
                </Box>

                <Box
                  sx={{
                    gridArea: "box2",
                    overflow: "auto",
                    border: styleBorderDarkMode(),
                    borderRadius: "5px",
                  }}
                >
                  <Table size="small">
                    <TableBody>
                      <Box sx={{ width: "100%" }}>
                        <TableRow>
                          <TableCell
                            sx={{ padding: "6px 6px 6px 16px" }}
                            scope="row"
                          >
                            <strong style={styles.titleRow}>Cliente:</strong>
                          </TableCell>
                          <TableCell sx={styles.rowCart} scope="row">
                            {budget.observations}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{ padding: "6px 6px 6px 16px" }}
                            scope="row"
                          >
                            <strong style={styles.titleRow}>Taller:</strong>
                          </TableCell>
                          <TableCell sx={styles.rowCart} scope="row">
                            {budget.workshop_observations}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{ padding: "6px 6px 6px 16px" }}
                            scope="row"
                          >
                            <strong style={styles.titleRow}>LAX:</strong>
                          </TableCell>
                          <TableCell sx={styles.rowCart} scope="row">
                            {budget.lax_observations}
                          </TableCell>
                        </TableRow>
                      </Box>
                    </TableBody>
                  </Table>
                </Box>

                <Box
                  sx={{
                    background: styleBgTitelDarkMode(),
                    padding: "3px",
                    borderRadius: "5px",
                    textAlign: "center",
                    border: styleBorderDarkMode(),
                    gridArea: "titel3",
                  }}
                >
                  <strong>Fechas</strong>
                </Box>

                <Box sx={{ gridArea: "box3" }}>
                  <Table sx={{ height: "100%" }} size="small">
                    <TableBody
                      sx={{
                        display: "flex",
                        border: styleBorderDarkMode(),
                        borderRadius: "5px",
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                        }}
                      >
                        <TableRow>
                          <TableCell
                            sx={{ padding: "6px 6px 6px 16px" }}
                            scope="row"
                          >
                            <strong style={styles.titleRow}>Creación:</strong>
                          </TableCell>
                          <TableCell sx={styles.rowCart} scope="row">
                            {parseUTCDate(budget.creation_date)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{ padding: "6px 6px 6px 16px" }}
                            scope="row"
                          >
                            <strong style={styles.titleRow}>Ingreso:</strong>
                          </TableCell>
                          <TableCell sx={styles.rowCart} scope="row">
                            {budget.workshop_arrival_date
                              ? parseUTCDate(budget.workshop_arrival_date)
                              : "-"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{ padding: "6px 6px 6px 16px" }}
                            scope="row"
                          >
                            <strong style={styles.titleRow}>Turno:</strong>
                          </TableCell>
                          <TableCell sx={styles.rowCart} scope="row">
                            {budget.appointment_date
                              ? parseUTCDate(budget.appointment_date)
                              : "-"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{ padding: "6px 6px 6px 16px", border: "none" }}
                            scope="row"
                          >
                            <strong style={styles.titleRow}>Retiro:</strong>
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "100%",
                              padding: "6px",
                              border: "none",
                            }}
                            scope="row"
                          >
                            {budget.workshop_exit_date
                              ? parseUTCDate(budget.workshop_exit_date)
                              : "-"}
                          </TableCell>
                        </TableRow>
                      </Box>
                    </TableBody>
                  </Table>
                </Box>
                <Box
                  sx={{
                    background: styleBgTitelDarkMode(),
                    padding: "3px",
                    borderRadius: "5px",
                    textAlign: "center",
                    border: styleBorderDarkMode(),
                    gridArea: "titel4",
                  }}
                >
                  <strong>Solicitud de compra</strong>
                </Box>
                <Box sx={{ gridArea: "box4" }}>
                  <Table sx={{ height: "100%" }} size="small">
                    <TableBody
                      sx={{
                        display: "flex",
                        border: styleBorderDarkMode(),
                        borderRadius: "5px",
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                        }}
                      >
                        <TableRow>
                          <TableCell
                            sx={{ padding: "6px 6px 6px 16px" }}
                            scope="row"
                          >
                            <strong style={styles.titleRow}>ID:</strong>
                          </TableCell>
                          <TableCell sx={styles.rowCart} scope="row">
                            {purchaseInBudget?.id}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{ padding: "6px 6px 6px 16px" }}
                            scope="row"
                          >
                            <strong style={styles.titleRow}>Estado:</strong>
                          </TableCell>
                          <TableCell sx={styles.rowCart} scope="row">
                            {purchaseInBudget?.workshopPurchaseState.name}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{ padding: "6px 6px 6px 16px" }}
                            scope="row"
                          >
                            <strong style={styles.titleRow}>Creación:</strong>
                          </TableCell>
                          <TableCell sx={styles.rowCart} scope="row">
                            {purchaseInBudget
                              ? parseUTCDate(purchaseInBudget.created_at)
                              : "-"}
                          </TableCell>
                        </TableRow>
                      </Box>
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Paper>

            <Box
              sx={{
                marginTop: "10px",
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Asignación de mecanico" {...a11yProps(0)} />
                <Tab label="Gestión de Turnos" {...a11yProps(1)} />
                <Tab label="Ingresos / Engresos" {...a11yProps(2)} />
                <Tab label="Solicitud de Compra" {...a11yProps(3)} />
                <Tab label="Registros de Trabajo" {...a11yProps(4)} />
              </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
              <UserAssignment />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box>Gestión de Turnos</Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Box>Ingresos / Engresos</Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <Box>
                <Box>
                  {purchaseInBudget ? (
                    <Box>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ margin: "10px 0px 30px 0px" }}
                      >
                        <Alert sx={{ width: "100%" }} severity="info">
                          Los valores son cargados sin IVA
                        </Alert>
                      </Stack>
                      {purchases
                        .filter((purchase) => purchase.budget.id === budget.id)
                        .sort((a, b) => a.id - b.id)
                        .map((p) => (
                          <Box>
                            <Typography
                              sx={{ color: "gray" }}
                            >{`ID de solicitud de compra: ${p.id} `}</Typography>
                            <strong>Avance de la solicitud</strong>
                            <StateProgressBar
                              states={p.workshopPurchaseItems.map(
                                (i) => i.workshopPurchaseItemState.name
                              )}
                            />
                            <Box>
                              <WorkshopPurchaseItemList
                                isEditionDisabled={isEditionDisabled}
                                onPurchaseChange={onPurchaseChange}
                                purchase={p}
                              />
                            </Box>
                            <Box sx={{ margin: "15px 0px 15px 0px" }}>
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<GridExpandMoreIcon />}
                                  aria-controls="panel1-content"
                                  id="panel1-header"
                                >
                                  <strong>Actividad</strong>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Box>
                                    <History purchase={p} />
                                  </Box>
                                </AccordionDetails>
                              </Accordion>
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  ) : (
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ margin: "10px 0px 30px 0px" }}
                    >
                      <Alert sx={{ width: "100%" }} severity="info">
                        Presupuesto sin solicitud de compra
                      </Alert>
                    </Stack>
                  )}
                </Box>
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <Box>Registros</Box>
            </CustomTabPanel>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
