import ApiService from "./ApiService";
import { BaseApplicationService } from "../interfaces/base-applicaton-service.interface";
import { Budget } from "../entities/budget.entity";
import { BudgetState } from "../entities/budget-state.entity";

type Params = { page?: number; pageSize?: number; filters?: any };

export class BudgetService implements BaseApplicationService<Budget, Params> {
  findOneById(_id: number): Promise<Budget | undefined> {
    throw new Error("Method not implemented.");
  }

  findOne(_params: Params): Promise<Budget> {
    throw new Error("Method not implemented.");
  }

  private budgetStatesToInclude = [
    BudgetState.QUOTE_AT_WORKSHOP_ID,
    BudgetState.LAX_VERIFICATION_ID,
    BudgetState.BUDGETED_ID,
    BudgetState.APPROVED_BY_CLIENT_ID,
    BudgetState.APPROVED_BY_LAX_ID,
    BudgetState.IN_ACTION_ID,
    BudgetState.REQUESTED_BY_CLIENT_ID,
    BudgetState.RECOSTE_ID,
    BudgetState.RECOSTE_AT_WORKSHOP_ID,
    BudgetState.QUOTE_ADDITIONAL_ID,
    BudgetState.ADDITIONALS_QUOTED_ID,
  ];

  async findAllBudgets(): Promise<Budget[]> {
    let activeBudgets: Budget[] = [];
    let page = 1;
    let totalPages = 1;

    while (page <= totalPages) {
      const response = await this.find({
        page,
        filters: {
          "BudgetSearch[budget_state_id][]": this.budgetStatesToInclude,
        },
      });
      activeBudgets = [...activeBudgets, ...response.data];
      totalPages = Math.ceil(response.totalCount / 20);
      page++;
    }
    return activeBudgets;
  }

  async find(
    params?: Params
  ): Promise<{ data: Budget[]; totalCount: number; pageSize: number }> {
    const { page = 1, pageSize = 20, filters } = params || {};
    const response = await ApiService.get(`/api_v2/budget-workshop/index`, {
      params: {
        page,
        pageSize,
        ...filters,
      },
    });

    const data = response?.data.data.map((p: Budget) => new Budget(p)) || [];
    const totalCount = parseInt(
      response.headers?.["x-pagination-total-count"] || "0",
      10
    );

    const perPage = parseInt(
      response.headers?.["x-pagination-per-page"] || "20",
      10
    );

    return { data, totalCount, pageSize: perPage };
  }

  async update(budgetId: number, budget: Partial<Budget>): Promise<Budget> {
    try {
      const response = await ApiService.post(
        `/api_v2/budget-workshop/update?id=${budgetId}`,
        budget
      );

      if (response.data && response.data.success === false) {
        throw new Error(response.data.data);
      }

      if (!response.data || !response.data.data) {
        throw new Error("Unexpected response format");
      }

      return new Budget(response.data.data);
    } catch (error: any) {
      console.error("Error in BudgetService.update:", error);
      throw new Error(error.message || "Unknown error");
    }
  }

  async updateMechanics(
    budgetId: number,
    userIds: number[]
  ): Promise<void> {
    if (!budgetId) {
      throw new Error("Budget ID is invalid.");
    }

    const data = {
      budget_id: budgetId,
      user_ids: userIds,
    };

    try {
      await ApiService.post(`/api_v2/budget/update-mechanics`, data);
    } catch (error: any) {
      if (error.response) {
        throw new Error(
          error.response.data.error || "Error al asignar mecánicos"
        );
      } else if (error.request) {
        throw new Error("No se recibió respuesta del servidor");
      } else {
        throw new Error("Error al asignar mecánicos");
      }
    }
  }
}
