import { useState } from "react";
import { useAppDispatch } from "../redux-store/hooks";
import { useDarkMode } from "./ThemeContext";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  ToggleButton,
  Tooltip,
  Typography,
} from "@mui/material";

import { Logout } from "@mui/icons-material";
import { removeToken } from "../services/AuthService";
import { removeUser } from "../redux-store/slices/UserSlice";
import { User } from "../entities/user.entity";
import ThemeSwitcher from "./ThemeSwitcher";

interface propsProfile {
  user: User;
}

export default function ProfileDropdown({ user }: propsProfile) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();

  const { isDarkMode } = useDarkMode();
  const openAvatar = Boolean(anchorEl);

  const handleLogout = () => {
    removeToken();
    dispatch(removeUser());
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Configuración de Cuenta">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-haspopup="true"
        >
          <Avatar
            sx={{
              width: 32,
              height: 32,
              bgcolor: isDarkMode ? "none" : "white",
            }}
          >
            <Typography sx={{ color: isDarkMode ? "none" : "black" }}>
              {user.name.substring(0, 1)}
            </Typography>
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openAvatar}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              minWidth: "200px",
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box sx={{ margin: "5px 10px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar /> {user.name}
          </Box>
          <Box
            sx={{
              marginTop: "10px",
            }}
          >
            <Typography variant="caption">Rol:</Typography>
            <Typography>{user.roles[0]}</Typography>
          </Box>
        </Box>

        <Divider />
        <Box m={1}>
          <ThemeSwitcher />
        </Box>
        <Box m={1}>
          <ToggleButton
            value="check"
            onClick={handleLogout}
            sx={{
              width: "100%",
              justifyContent: "left",
              textTransform: "none",
            }}
          >
            <Logout fontSize="small" />
            <Typography sx={{ marginLeft: "10px" }}>Salir</Typography>
          </ToggleButton>
        </Box>
      </Menu>
    </>
  );
}
