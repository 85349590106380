import {
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import React from "react";

interface ComponentProps {
  to: string;
  icon: React.ReactNode;
  text: string;
  open: boolean;
}

export default function AppLink({ to, icon, text, open }: ComponentProps) {
  const location = useLocation();

  return (
    <Link component={RouterLink} to={to} underline="none" color="inherit">
      <ListItemButton
        selected={location.pathname === to}
        sx={{
          borderRadius: "7px",
          marginBottom: 1,
        }}
      >
        <ListItemIcon>
          {React.cloneElement(icon as React.ReactElement, {
            fontSize: open ? "medium" : "small",
          })}
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </Link>
  );
}
